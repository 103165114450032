import type { PropsWithChildren } from 'react';
import { useContext } from 'react';
import { createContext } from 'react';
import { analytics } from '../firebase.client';

export const firebaseContext = createContext({
  analytics,
});

export const FirebaseProvider = ({ children }: PropsWithChildren<{}>) => {
  return (
    <firebaseContext.Provider value={{ analytics }}>
      {children}
    </firebaseContext.Provider>
  );
};

export const useFirebase = () => {
  return useContext(firebaseContext);
};
